











































































































































































































































































































































.editor {
  position: relative;
  width: 100%;
  border: 1px solid #dcdfe6;
  padding: 24px 24px;
  margin-bottom: 40px;
  border-radius: 4px;

  .editor__content {
    word-wrap: break-word;
  }
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: #444;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: white;
  }
}

.file-input {
  display: none;
}
